import { MenuItem } from 'primeng/api';

export class BreadcrumbState {
  navigationId: number;
  breadcrumbItems: MenuItem[];
  home?: MenuItem;

  constructor(id: number, breadcrumbItems: MenuItem[], home?: MenuItem) {
    this.navigationId = id;
    this.breadcrumbItems = breadcrumbItems;
    this.home = home;
  }
}

export class FlowMapAction {
  routerLink: string;
  state?: { [key: string]: string };

  constructor(routerLink: string, state?: { [key: string]: string }) {
    this.routerLink = routerLink;
    this.state = state;
  }
}

export class FlowMapPage {
  layoutElements?: string[];
  actions?: Record<string, FlowMapAction>;
}

export class FlowMapPageWithHistoryOverride extends FlowMapPage {
  inHistory?: Record<string, FlowMapPage>;
}

export class FlowMap {
  defaultLayoutElements: string[];
  flowMap: Record<string, FlowMapPageWithHistoryOverride>;

  constructor(defaultLayoutElements: string[], flowMap: Record<string, FlowMapPageWithHistoryOverride>) {
    this.defaultLayoutElements = defaultLayoutElements;
    this.flowMap = flowMap;
  }
}
